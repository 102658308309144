$primary: #fd6f6e;
$primary-light: #161d31b5;
$bg-color: #fafafa;
$secondary: #000000;
$grey: #b9b9c3;
$sy-white: #ffffff;
$sy-black: #000000;
$sy-dark: #020000;
$sy-third: #f3f2f7;
$sy-fourth: #d8d6de;
$dark-gray: #6d7381;
$light-grey: #838383;
$dark-black: #3a3a3a;
$error: #e73d3e;
$bg-color-2: #dfdddd;
$modal: #5e5873;
$modal-2: #6e6b7b;
$gradient: #6786d2;
/*font-size root*/
$font-family: 'Montserrat', sans-serif;
$sy-h1: 24px;
$sy-h1-lh: 21px;
$sy-h2: 20px;
$sy-h2-lh: 30px;
$sy-h3: 18px;
$sy-h3-lh: 22px;
$sy-h4: 16px;
$sy-h4-lh: 24px;
$sy-h5: 14px;
$sy-h5-lh: 21px;
$sy-h6: 12px;
$sy-h6-lh: 18px;
$body-font-size: 15px;
$paragraph-font-size: 16px;
$paragraph-lh: 23px;
$bl-radius: 5px;
$bl-radius1: 6px;
$bl-radius2: 8px;
$bl-radius3: 12px;
$bl-radius4: 14px;
$bl-radius5: 20px;
// fontweight
// fontweight
$fw100: 100;
$fw300: 300;
$fw400: 400;
$fw500: 500;
$fw600: 600;
$fw700: 700;
$fw900: 900;

@mixin transition {
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}
/*text color classes*/
.sy-tx-primary {
    color: $primary;
}

.sy-tx-secondary {
    color: $secondary;
}

.sy-tx-grey {
    color: $grey;
}

.sy-tx-third {
    color: $sy-third;
}

.sy-tx-fourth {
    color: $sy-fourth;
}

.sy-tx-black {
    color: $sy-black;
}
.sy-text-dark {
    color: $sy-dark;
}

.sy-tx-white {
    color: $sy-white !important;
}
.sy-tx-modal {
    color: $modal !important;
}
.sy-tx-modal2 {
    color: $modal-2 !important;
}

/*background color classes*/
.sy-bg-primary {
    background-color: $primary;
}

.sy-bg-secondary {
    background-color: $secondary;
}

.sy-bg-white {
    background-color: $sy-white;
}

.sy-bg-black {
    background-color: $sy-black;
}

/*font-weight classes*/
.f-100 {
    font-weight: $fw100 !important;
}

.f-300 {
    font-weight: $fw300 !important;
}

.f-400 {
    font-weight: $fw400 !important;
}

.f-500 {
    font-weight: $fw500 !important;
}

.f-600 {
    font-weight: $fw600 !important;
}

.f-700 {
    font-weight: $fw700 !important;
}

.f-900 {
    font-weight: $fw900 !important;
}

.f-bold {
    font-weight: bold;
}
.f-15 {
    font-size: 15px;
}

// responsive class
// Small tablets and large smartphones (landscape view)

$screen-sm-max: 576px;
// Small tablets (portrait view)
$screen-md-max: 768px;
// Tablets and small desktops
$screen-lg-max: 992px;
// Large tablets and desktops
$screen-xl-max: 1200px;
// Extra Large tablets and desktops
$screen-xll-max: 1399px;
// Small devices
@mixin sm {
    @media (max-width: #{$screen-sm-max}) {
        @content;
    }
}
// Medium devices
@mixin md {
    @media (max-width: #{$screen-md-max}) {
        @content;
    }
}
// Large devices
@mixin lg {
    @media (max-width: #{$screen-lg-max}) {
        @content;
    }
}
// Extra large devices
@mixin xl {
    @media (max-width: #{$screen-xl-max}) {
        @content;
    }
}
// Double Extra large devices
@mixin xll {
    @media (max-width: #{$screen-xll-max}) {
        @content;
    }
}

a,
a:hover {
    text-decoration: none;
}

// change-password css start here

.change-auth {
    align-items: unset !important;
    min-height: auto !important;
    .change-inner {
        max-width: 100% !important;
        &::before {
            background-image: none !important;
        }
        &::after {
            background-image: none !important;
        }
    }
}

//custom color
.primary {
    color: $primary !important;
}

// custom btn

.btn {
    &.custom-btn {
        min-height: 45px;
        height: 45px;
        background-color: $primary !important;
        border: 1px solid $primary !important;
        box-sizing: border-box;
        box-shadow: 0px 0px 8px rgba($sy-black, 0.08);
        border-radius: 5px;
        padding: 12px 12px;
        font-weight: $fw500;
        color: $sy-white !important;
        font-size: $sy-h5;
        line-height: $sy-h5-lh;
        min-width: 107px;
        transition: 0.3s ease-in-out;
        white-space: nowrap;
        &:hover,
        &:focus {
            box-shadow: 0 8px 25px -8px $primary !important;
        }
        @include md {
            min-height: 40px;
            height: 40px;
            padding: 10px;
            font-size: $sy-h6;
            line-height: $sy-h6-lh;
        }
        @include sm {
            width: 100%;
        }
    }
    &.custom-btn5 {
        min-height: 45px;
        height: 45px;
        background-color: $secondary !important;
        border: 1px solid $secondary !important;
        box-sizing: border-box;
        box-shadow: 0px 0px 8px rgba($sy-black, 0.08);
        border-radius: 5px;
        padding: 12px 12px;
        font-weight: $fw500;
        color: $sy-white !important;
        font-size: $sy-h5;
        line-height: $sy-h5-lh;
        max-width: 166px !important;
        transition: 0.3s ease-in-out;
        white-space: nowrap;
        &:hover,
        &:focus {
            box-shadow: 0 8px 25px -8px $secondary !important;
        }
        @include md {
            min-height: 40px;
            height: 40px;
            padding: 10px;
            font-size: $sy-h6;
            line-height: $sy-h6-lh;
        }
        @include sm {
            width: 100%;
        }
    }
    &.custom-btn2 {
        min-height: 45px;
        height: 45px;
        background-color: transparent !important;
        color: $secondary !important;
        border: 1px solid $secondary !important;
        box-sizing: border-box;
        box-shadow: 0px 0px 8px rgba($sy-black, 0.08);
        border-radius: 5px;
        padding: 5px 12px;
        font-weight: $fw500;
        font-size: $sy-h5;
        line-height: $sy-h5-lh;
        transition: 0.3s ease-in-out;
        white-space: nowrap;
        &:hover,
        &:focus {
            box-shadow: none !important;
            background-color: $secondary !important;
            color: $sy-white !important;
        }
        // &:disabled{
        //   background-color: $sy-third !important;
        // }
        @include md {
            min-height: 40px;
            height: 40px;
            padding: 10px;
            font-size: $sy-h6;
            line-height: $sy-h6-lh;
        }
        @include sm {
            width: 100%;
        }
    }
    &.custom-btn3 {
        min-height: 35px;
        height: 35px;
        background-color: transparent !important;
        border: 1px solid $primary !important;
        box-sizing: border-box;
        box-shadow: 0px 0px 8px rgba($sy-black, 0.08);
        border-radius: 5px;
        padding: 6px 6px;
        font-weight: $fw500;
        color: $primary !important;
        font-size: $sy-h5;
        line-height: $sy-h5-lh;
        min-width: 60px;
        transition: 0.3s ease-in-out;
        white-space: nowrap;
        &:hover,
        &:focus {
            box-shadow: none !important;
            background-color: $primary !important;
            color: $sy-white !important;
        }
        @include md {
            min-height: 40px;
            height: 40px;
            padding: 10px;
            font-size: $sy-h6;
            line-height: $sy-h6-lh;
        }
        @include sm {
            width: 100%;
        }
    }
    &.custom-btn4 {
        min-height: 40px;
        height: 40px;
        width: 90px;
        background-color: $primary !important;
        border: 1px solid $primary !important;
        color: $sy-white !important;
        box-sizing: border-box;
        box-shadow: 0px 0px 8px rgba($sy-black, 0.08);
        border-radius: 5px;
        padding: 9px 10px;
        font-weight: $fw500;
        font-size: $sy-h5;
        line-height: $sy-h5-lh;
        min-width: 60px;
        transition: 0.3s ease-in-out;
        white-space: nowrap;

        &:hover,
        &:focus {
            box-shadow: 0 8px 25px -8px $primary !important;
        }
        @include md {
            min-height: 40px;
            height: 40px;
            padding: 10px;
            font-size: $sy-h6;
            line-height: $sy-h6-lh;
        }
        @include sm {
            width: 100%;
        }
    }
    &.custom-btn6 {
        min-height: 38px;
        height: 38px;
        background-color: $primary !important;
        color: $sy-white !important;
        border: 1px solid $primary !important;
        box-sizing: border-box;
        box-shadow: 0px 0px 8px rgba($sy-black, 0.08);
        border-radius: 5px;
        padding: 5px 12px;
        font-weight: $fw500;
        font-size: $sy-h5;
        line-height: $sy-h5-lh;
        transition: 0.3s ease-in-out;
        white-space: nowrap;

        &:hover,
        &:focus {
            box-shadow: none !important;
            background-color: transparent !important;
            color: $primary !important;
        }

        // &:disabled{
        //   background-color: $sy-third !important;
        // }
        @include md {
            min-height: 40px;
            height: 40px;
            padding: 10px;
            font-size: $sy-h6;
            line-height: $sy-h6-lh;
        }

        @include sm {
            width: 100%;
            margin-top: 12px;
        }
    }
    &.custom-btn7 {
        min-height: 40px;
        height: 40px;
        width: 90px;
        background-color: transparent !important;
        border: 1px solid $secondary !important;
        color: $sy-black !important;
        box-sizing: border-box;
        box-shadow: 0px 0px 8px rgba($sy-black, 0.08);
        border-radius: 5px;
        padding: 9px 10px;
        font-weight: $fw500;
        font-size: $sy-h5;
        line-height: $sy-h5-lh;
        min-width: 60px;
        transition: 0.3s ease-in-out;
        white-space: nowrap;

        &:hover,
        &:focus {
            box-shadow: none !important;
            background-color: $secondary !important;
            color: $sy-white !important;
        }
        @include md {
            min-height: 40px;
            height: 40px;
            padding: 10px;
            font-size: $sy-h6;
            line-height: $sy-h6-lh;
        }
        @include sm {
            width: 100%;
        }
    }
}

// custom pagination  and other component css
.pagination {
    .page-item.active .page-link {
        border-radius: 5rem;
        background-color: $primary;
        z-index: 0;
    }
    .page-link {
        &:hover {
            color: $primary;
        }
    }
}
.form-check-input:checked {
    background-color: $primary;
    border-color: $primary;
}
.form-control {
    &:focus {
        background-color: transparent !important;
        border-color: $primary !important;
    }
}

.breadcrumb-item,
.home {
    a {
        color: $primary;
    }
}
.text-primary {
    color: $primary !important;
}
.main-role-ui {
    .breadcrumb-right {
        display: none;
    }
}
.form-check-input:focus {
    border-color: $primary;
    box-shadow: none;
}
.dropdown-item:hover,
.dropdown-item:focus {
    background-color: transparent;
    color: black;
}
.select__control:focus,
.select__control:focus-visible,
.select__control:active,
.select__control:focus-within,
.select__control:target,
.select__control:visited {
    border: 1px solid $primary !important;
}
.input-group:not(.bootstrap-touchspin):focus-within .input-group-text {
    border-color: $primary;
}
.back-arrow-h {
    display: flex;
    align-items: center;
    a {
        color: $primary;
        padding-right: 10px;
    }
}
.text-right {
    text-align: right;
}
// spinner loader
.fallback-spinner .loading .effect-1,
.fallback-spinner .loading .effect-2,
.fallback-spinner .loading .effect-3 {
    border-left: 3px solid $sy-black;
}

// input group css

.field-border {
    border-right-color: transparent !important;
    &:focus {
        border-right-color: transparent !important;
    }
}
.input-group-text {
    border-left-color: transparent !important;
    &:focus {
        border-left-color: transparent !important;
    }
}

th {
    font-size: $sy-h5 !important;
    padding: 0.5rem 1.5rem !important;
}
td {
    padding: 0.5rem 1.5rem !important;
}

.add-form-header {
    font-size: $sy-h4 !important;
    line-height: $sy-h4-lh !important;
}

// input radio css
input[type='radio']#ex1-active {
    accent-color: green;
}
input[type='radio']#ex1-inactive {
    accent-color: red;
}
.no-data-found {
    min-height: 45px;

    .no-data {
        position: absolute;
        right: 0;
        left: 0;
        max-height: 200px;
        .font14 {
            font-size: 14px !important;
        }
    }
}
.sc-AxhCb.sc-fznZeY.dVrMrf.rdt_TableCol {
    display: none !important;
}
.sc-AxhCb.sc-AxheI.UXnxA.rdt_TableCell {
    display: none !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    background-color: $primary !important;
    border-color: $primary !important;
    box-shadow: none !important;
}
// data-table checkbox
.sc-AxhCb.sc-fznZeY.dmWWTk.rdt_TableCol {
    display: none !important;
}
.sc-AxhCb.sc-AxheI.hlQzeM.rdt_TableCell {
    display: none !important;
}
.sc-AxhCb.sc-fznZeY.dVrMrf.rdt_TableCol {
    display: none !important;
}
.sc-AxhCb.sc-AxheI.UXnxA.rdt_TableCell {
    display: none !important;
}
.single-line-elipsis {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 110px;
}
.status-select {
    width: 100%;
    max-width: 220px;
}
.availability-select {
    width: 100%;
    max-width: 150px;
    margin-left: 0 !important;
    &.dashboard-select {
        max-width: 130px !important;
        .select__indicator,
        .select__value-container {
            padding: 0 4px 0 8px !important;
        }
    }
    .select__control {
        border-radius: 50rem;
        height: 30px !important;
        min-height: 30px !important;
    }
    .select__indicator {
        padding: 0 8px 0 !important;
    }
    .select__menu .select__menu-list .select__option:hover {
        background-color: #f1f1f4 !important;
    }
    &.avail-color {
        .select__control {
            background: rgba(40, 199, 111, 0.12) !important;
            border-color: rgba(40, 199, 111, 0.12) !important;

            .select__value-container {
                .select__single-value {
                    color: #28c76f !important;
                    font-weight: 600;
                }
            }
        }
        .select__menu .select__menu-list .select__option.select__option--is-selected {
            background: rgba(40, 199, 111, 0.12) !important;
        }
        .select__control .select__indicator svg {
            color: #28c76f !important;
        }
    }
    &.unavail-color {
        .select__control {
            background: rgba(234, 84, 85, 0.12) !important;
            border-color: rgba(234, 84, 85, 0.12) !important;

            .select__value-container {
                .select__single-value {
                    color: $primary !important;
                    font-weight: 600;
                }
            }
        }
        .select__menu .select__menu-list .select__option.select__option--is-selected {
            background: rgba(234, 84, 85, 0.12) !important;
        }
        .select__control .select__indicator svg {
            color: #28c76f !important;
            color: $primary !important;
        }
    }
}
.margin-bottom {
    @include lg {
        margin-bottom: 15px;
    }
}
.cu-avatar {
    background: rgba(255, 159, 67, 0.12);
    color: #ea5455;
}
.select__menu-portal {
    z-index: 999999 !important;
}

.react-dataTable {
    > div {
        min-height: 380px;
    }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.item-center {
    display: flex;
    justify-content: center;
    align-items: center;
    &.justify-start {
        justify-content: flex-start;
    }
    &.justify-end {
        justify-content: flex-end;
    }
}

.font2024 {
    font-size: 20px !important;
    line-height: 24px !important;
}

.font1624 {
    font-size: 16px !important;
    line-height: 24px !important;
}

@mixin font($size, $lineHeight) {
    font-size: $size !important;
    line-height: $lineHeight !important;
}